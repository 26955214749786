<template>
	<div class="product-container">
		<div class="scroll-container">
			<div class="bg-wrapper" :style="`background-image: url(${backgroundImg});`">
				<div class="content-wrapper">
					<ul class="left">
						<li v-for="(item, index) of productList" :class="activeTab === index ? 'active-wrapper' : ''" :key="item.id" @click="handleToggleTab(index, item.id, item)">
							<span :class="['tab ' + item.icon, { active: activeId == item.id }]">{{ item.name }}</span>
						</li>
					</ul>
					<div class="right">
						<ul class="nav">
							<li
								class="title"
								v-for="(item, index) in productContentList"
								:key="item.id"
								:class="{ active: activeTitle == index }"
								@click="handleToggleTitle(index, item)"
							>
								{{ item.shortName }}
							</li>
							<!-- <li v-show="activeId == 1" class="title" :class="{active: activeTitle === '0'}" @click="handleToggleTitle('0')">格华止®</li>
              <li v-show="activeId == 1" class="title" :class="{active: activeTitle === '1'}" @click="handleToggleTitle('1')">怡可安®</li>
              <li v-show="activeId == 1" class="title" :class="{active: activeTitle === '2'}" @click="handleToggleTitle('2')">导升明®</li>
              <li v-show="activeId == 2" class="title" :class="{active: activeTitle === '3'}" @click="handleToggleTitle('3')">康忻®</li>
              <li v-show="activeId == 4" class="title" :class="{active: activeTitle === '4'}" @click="handleToggleTitle('4')">赛治®</li>
              <li v-show="activeId == 4" class="title" :class="{active: activeTitle === '5'}" @click="handleToggleTitle('5')">优甲乐®</li>
              <li v-show="activeId == 3" class="title" :class="{active: activeTitle === '6'}" @click="handleToggleTitle('6')">思他宁®</li> -->
						</ul>
						<div class="main-content">
							<img :src="data && data.coverImg" class="img" alt="" />
							<div class="r">
								<h3 class="t">{{ data && data.name }}</h3>
								<h3 class="t st">{{ data && data.subTitle }}</h3>
								<p class="des" v-html="data.functions"></p>
								<span :class="['star', { active: isClocActive }]" @click="isCollection(data && data.id)">{{ clocText }}</span>
							</div>
						</div>
						<div class="product-info"><h4 class="info">产品介绍</h4></div>
						<div class="details-content">
							<a :href="data && data.instructions" @click="downInstr(data.id)" target="_blank" class="pdf" download="说明书">说明书下载</a>
							<div ref="detailText2" class="hidden"><p v-html="data.info"></p></div>
							<div ref="detailText" :class="['details', { active: isActive }]">
								<p v-html="data.info"></p>
								<span v-show="!isActive">
									<i class="spot">...</i>
									<i class="more" @click="getMore(data.id)">【查看更多】</i>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="news-wrapper"><product-news v-if="productId" :productId="productId" :producType="activeId"></product-news></div>
			</div>
		</div>
	</div>
</template>

<script>
import indexApi from '@/api/index';
import ProductNews from './product-news';
export default {
	components: { ProductNews },
	data() {
		return {
			activeTab: 0,
			activeTitle: 0,
			classBg: 'bg3',
			productType: '',
			productId: '',
			isClocActive: false,
			clocText: '收藏',
			activeId: '',
			data: {},
			backgroundImg: '',

			productList: [],

			productContentList: [],

			iconActive: '',
			isActive: true
		};
	},
	created() {
		if (this.$route.query.id) {
			this.queryId = this.$route.query.id;
		} else if (sessionStorage.getItem('productId')) {
			this.queryId = sessionStorage.getItem('productId');
		}

		console.log('this.queryIdthis.queryId', this.queryId);
		this.getProductList();
	},
	mounted() {
		//this.getData();
	},
	//销毁
	destroyed() {
		console.log('销毁');
		//sessionStorage.removeItem('productId')
	},
	methods: {
		downInstr(id) {
			//说明书下载
			let that = this;
			let params = {
				dataId: id, //数据ID
				type: '1' //类型 1产品 2活动 3资讯 4 临床视角
			};
			that.$point.download(params);
		},
		getMore(id) {
			// 点击查看更多详情
			let that = this;
			that.isActive = true;
			let button_params = {
				button: '查看更多',
				buttonContent: '',
				dataId: id,
				type: parseInt(1)
			};
			that.$point.buttonClickFn(button_params);
		},

		handleToggleTab(i, id, item) {
			//this.activeTab = i;
			this.activeId = id;
			this.productContentList = item && item.productList;
			this.data = (item && item.productList && item.productList[0]) || {};
			this.backgroundImg = this.data.backgroundImg;
			this.activeTitle = 0;
			this.productId = this.data.id;
			this.isClocActive = !this.data.followStatus;

			this.clocText = this.isClocActive ? '取消' : '收藏';
			this.checkMore();

			let newArr = [];
			for (let n = 0; n < 4; n++) {
				newArr.push(this.productList[i]);
				i++;
				if (i >= 4) {
					i = 0;
				}
			}
			this.productList = newArr;
			//   for(var i = 0; i < newArr.length; i++){
			//       console.log(newArr[i].name)
			//   }
		},

		handleToggleTitle(n, item) {
			this.productId = item.id;
			this.activeTitle = n;
			switch (n) {
				case '0':
					this.classBg = '';
					break;
				case '1':
					this.classBg = 'bg1';
					break;
				case '2':
					this.classBg = 'bg2';
					break;
				case '3':
					this.classBg = 'bg3';
					break;
				case '4':
					this.classBg = 'bg4';
					break;
				case '5':
					this.classBg = 'bg5';
					break;
				case '6':
					this.classBg = 'bg6';
					break;
				case '7':
					this.classBg = 'bg7';
					break;
			}

			this.data = item || {};
			this.backgroundImg = item && item.backgroundImg;

			this.isClocActive = !this.data.followStatus;

			this.clocText = this.isClocActive ? '取消' : '收藏';
			this.checkMore();
		},
		/**
		 * 选中当前产品
		 */
		selectTab(index, tabIndex) {
			this.activeTab = index;
			this.handleToggleTitle(tabIndex, this.data);
		},
		getData() {
			this.data = {
				title: '格华止®',
				subTitle: '盐酸二甲双胍片',
				des: '格华止（盐酸二甲双胍片），适应症为本品首选用于单纯饮食及体育活动不能有效控制的2型糖尿病，特别是肥胖的2型糖尿病。',
				details: '',
				img: './images/p-1.png',
				pdf: 'https://www.aa.com/234.pdf'
			};
		},

		// 获取产品列表
		getProductList() {
			let params = {
				// "condition": {},
				// "page": 1,//页码
				// "size": 2//页大小
			};
			indexApi.getProductList(params).then(
				res => {
					if (res && res.code === 1000) {
						let data = (res && res.data) || [];

						// 产品名字处理
						data.map((item, index) => {});

						let pList = data; //当前的左侧列表
						let currIndex = 0;
						let currTabIndex = 0;
						if (this.queryId) {
							//如果有id，默认选择，需要改变列表顺序
							for (var i = 0; i < pList.length; i++) {
								for (var j = 0; j < pList[i].productList.length; j++) {
									if (pList[i].productList[j].id == this.queryId) {
										currIndex = i;
										currTabIndex = j;
										break;
									}
								}
							}
						}
						//改变列表顺序，将默认的产品放到第一个
						let currI = currIndex;
						let newArr = [];
						for (let n = 0; n < pList.length; n++) {
							newArr.push(pList[currI]);
							currI++;
							if (currI >= pList.length) {
								currI = 0;
							}
						}
						this.productList = newArr;
						currIndex = 0; //列表顺序改变之后，选中第一个
						console.log('第一次获取完列表=========', currIndex, this.productList);
						let currData = this.productList[0];
						this.activeId = currData.id;
						this.productContentList = currData.productList;
						this.data = currData.productList[currTabIndex];
						this.backgroundImg = this.data && this.data.backgroundImg;
						this.productId = this.data && this.data.id;
						this.isClocActive = this.data && !this.data.followStatus;
						this.clocText = this.isClocActive ? '取消' : '收藏';
						this.checkMore();

						console.log('data.info', this.data.info.length);
						this.selectTab(currIndex, currTabIndex);

						this.productList.map((item, index) => {
							if (item.name.indexOf('心血管') != -1) {
								item.icon = 'h';
							} else if (item.name.indexOf('甲状腺') != -1) {
								item.icon = 'x';
							} else if (item.name.indexOf('消化') != -1) {
								item.icon = 'w';
							} else if (item.name.indexOf('糖尿') != -1) {
								item.icon = 's';
							}
						});
					}
				},
				err => {
					this.$message.success((err && err.message) || '获取失败，请联系管理员！');
				}
			);
		},

		//判断是否显示查看更多
		checkMore() {
			this.$nextTick(() => {
				if (this.$refs.detailText2.offsetHeight > this.$refs.detailText.offsetHeight) {
					this.isActive = false;
				} else {
					this.isActive = true;
				}
			});
		},

		// 收藏
		isCollection(id) {
			let params = {
				dataId: id, //数据ID
				type: '1' //类型 1产品 2活动 3资讯 4 临床视角
			};
			indexApi.followOrCanFollow(params).then(
				res => {
					this.isClocActive = !this.isClocActive;
					this.isClocActive ? (this.clocText = '取消') : (this.clocText = '收藏');
				},
				err => {
					this.$message.success((err && err.message) || '操作失败，请联系管理员！');
				}
			);
		}
	}
};
</script>

<style scoped lang="scss">
.scroll-container {
	overflow: initial;
}
.product-container {
	background-image: url('~./images/bg.jpg');
	background-size: cover;
	line-height: 1;
}

.bg-wrapper {
	display: flex;
	align-items: flex-start;
	background: url('~./images/content-bg.png') center center / 100% 100%;
	height: 7.3rem;
	margin-top: 0.6rem;
	position: relative;
	padding-left: 0.3rem;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	&.bg1 {
		background: url('~./images/bg1.png') center center / 100% 100%;
	}
	&.bg2 {
		background: url('~./images/bg2.png') center center / 100% 100%;
	}
	&.bg3 {
		background: url('~./images/bg3.png') center center / 100% 100%;
	}
	&.bg4 {
		background: url('~./images/bg4.png') center center / 100% 100%;
	}
	&.bg5 {
		background: url('~./images/bg5.png') center center / 100% 100%;
	}
	&.bg6 {
		background: url('~./images/bg6.png') center center / 100% 100%;
	}
}

.content-wrapper {
	display: flex;
	align-items: flex-start;
	width: 11.15rem;
	margin-right: 0.1rem;

	.left {
		flex: none;
		width: 1.8rem;
		margin-top: 1.26rem;

		li {
			cursor: pointer;
			margin-bottom: 0.6rem;
			position: relative;
			transition: all 0.3s;
			&.active-wrapper {
				position: absolute !important;
				left: -0.15rem !important;
				top: -0.3rem !important;
				background: url('~./images/search-samll.png') center center / 100% 100%;
				height: 1.3rem;
				width: 2rem;
				display: flex;
				align-items: center;
				padding: 0.3rem;
				transition: width 0.3s;
			}

			.tab {
				display: block;
				font-size: 0.18rem;
				color: #fff;
				padding-left: 0.44rem;
				height: 0.36rem;
				line-height: 0.36rem;
				white-space: nowrap;
				background: url('~./images/x.png') left center / auto 0.36rem no-repeat;
				transition: top 0.3s;
				&.x {
					background-image: url('~./images/x.png');
					&.active {
						background-image: url('~./images/x-click.png');
					}
				}
				&.w {
					background-image: url('~./images/w.png');
					&.active {
						background-image: url('~./images/w-click.png');
					}
				}

				&.s {
					background-image: url('~./images/s.png');
					&.active {
						background-image: url('~./images/s-click.png');
					}
				}

				&.h {
					&.active {
						background-image: url('~./images/h-click.png');
					}
					background-image: url('~./images/h.png');
				}
			}
		}
	}

	.right {
		flex: auto;
		max-height: 6.5rem;
		overflow: auto;
		background-color: #ffffff;
		border-radius: 0.2rem 0 0 0.8rem;
		padding: 0.5rem;
		.nav {
			border-bottom: 1px solid #ebebeb;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 0.4rem;
			.title {
				line-height: 1;
				padding: 0 0.05rem 0.18rem;
				font-size: 0.18rem;
				color: #1a1a1a;
				margin: 0 0.4rem -1px;
				position: relative;
				cursor: pointer;
				border-bottom: 1px solid transparent;
				&.active {
					color: #503291;
					border-color: #503291;
				}
				&:last-child {
					&:after {
						display: none;
					}
				}
				&:after {
					content: '';
					display: block;
					width: 1px;
					height: 0.1rem;
					background-color: #d2d4d6;
					position: absolute;
					top: 0.03rem;
					right: -0.4rem;
				}
			}
		}
		.main-content {
			display: flex;
			align-items: center;
			margin-bottom: 0.4rem;
			overflow: hidden;
			.img {
				width: 3.8rem;
				height: 2.12rem;
				margin-right: 0.2rem;
				margin-left: 0.2rem;
			}
			.r {
				position: relative;
				line-height: 1;
				padding-top: 0.24rem;
				flex: 1;
				.t {
					font-size: 0.26rem;
					color: #1a1a1a;
					margin-bottom: 0.12rem;
				}
				.st {
					margin-bottom: 0.23rem;
				}
				.des {
					font-size: 0.16rem;
					color: #666666;
					line-height: 0.3rem;
				}
				.star {
					padding-left: 0.26rem;
					height: 0.16rem;
					line-height: 0.18rem;
					font-size: 0.14rem;
					color: #333;
					display: block;
					position: absolute;
					right: 0;
					top: 0;
					background: url('~./images/star.png') left center / 0.16rem 0.16rem no-repeat;
					cursor: pointer;
					&.active {
						background: url('~./images/star-active.png') left center / 0.16rem 0.16rem no-repeat;
					}
				}
			}
		}
		.product-info {
			height: 0.42rem;
			background-color: #503291;
			border-radius: 4px;
			position: relative;
			margin-bottom: 0.23rem;
			.info {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 1.3rem;
				height: 0.38rem;
				text-align: center;
				line-height: 0.38rem;
				font-size: 0.16rem;
				color: #503291;
				background: url('~./images/info.png') center center / 100% 100%;
			}
		}
		.details-content {
			display: flex;
			align-items: center;
			color: #333333;
			font-size: 0.16rem;
			position: relative;
			overflow: hidden;
			.pdf {
				display: block;
				padding-top: 0.6rem;
				background: url('~./images/pdf.png') center top / 0.42rem 0.48rem no-repeat;
				white-space: nowrap;
				flex: none;
				margin-right: 0.4rem;
			}
			.hidden {
				width: 100%;
				position: absolute;
				visibility: hidden;
				line-height: 0.32rem;
				padding-left: 1.4rem;
				box-sizing: border-box;
				//   display: none;
			}
			.details {
				height: 1.6rem;
				line-height: 0.32rem;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				position: relative;
				flex: 1;
				&.active {
					overflow-x: hidden;
					overflow-y: scroll;
				}
				span {
					display: inline-block;
					position: absolute;
					right: 0;
					bottom: 0;
					background: #fff;
					line-height: 0.32rem;
					i {
						font-style: normal;
					}
				}
				.spot {
					display: inline-block;
					background: #fff;
					color: #333333;
				}
				.more {
					color: #eb3c96;
					display: inline-block;
					cursor: pointer;
				}
			}
		}
	}
}
</style>
