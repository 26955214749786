<template>
    <div class="product-news">
      <h3 class="title icon">产品动态</h3>
      <ul :class="['list',{'active': isActive}]" v-if="list.length">
        <li v-for="item of list" :key="item.id" @click="goDetail(item)">
          <div class="item-content itemMinHeight">
            <img v-if="item.titleImg" :src="item.titleImg" class="img" alt="">
            <p class="t">{{item.title}}</p>
            <p class="t time">{{item.releaseTime}}</p>
          </div>
          <div class="shadow"></div>
        </li>
      </ul>
      <div class="list empty-box" v-else>
          暂无数据
      </div>
      <div class="more">
        <span class="more-t" @click="getMore">点击加载更多</span>
      </div>
    </div>
</template>

<script>
import indexApi from '@/api/index'
export default {
  name: "product-news",
  data() {
    return {
      list: [],
      isActive: false,
      pageSize: 2,
      page: 1,
      overSize:2,//剩余数量
      total:2
    }
  },
  props:{
    producType: {
      type: [String, Number],
      default: ''
    },
    productId: {
      type: [String, Number],
      default: ''
    }
  },
  created(){
    this.getProductDynamicList();
  },
  mounted() {
    // this.getData();
  },
  watch:{
    productId(oldVal,newVal) {
      this.page = 1;
      this.list = [];
      this.getProductDynamicList();
    }
  },
  methods: {

    goDetail(item){
      // this.$tools.windowOpenByRouterParmas.call(this,{
      //   name: 'productDetail',
      //   params:{ id: item && item.id},
      //   query: {
      //     producType: this.producType
      //   }
      // })
      console.log('设置id======')
      sessionStorage.setItem('productId',this.productId);
      this.$router.push({
        name: 'productDetail',
        params:{ id: item && item.id},
        query: {
          producType: this.producType,
          productId: this.productId
        }
      })
    },

    // 获取产品动态列表
    getProductDynamicList() {
      let params = {
        "condition": {
          productId: this.productId
        },
        "page": this.page,//页码
        "size": this.pageSize//页大小
      };
      indexApi.getProductDynamicList(params).then((res)=>{
        if(res && res.code === 1000){
          let data = res && res.data && res.data.list || [];
          this.page++;
          if(data.length != 0){
            this.list = this.list.concat(data);
            this.pageNums = res.data.pageNums;
            console.log('this.overSize',this.overSize,this.total)
          }
        }
      },(err)=>{
        this.$message.success(err && err.message || '获取失败，请联系管理员！')
      })
    },

    // 查看更多
    getMore() {
		let that = this;
		let button_params = {
			button: "点击加载更多",
			buttonContent: "",
			dataId: "",
			type: parseInt(5)
		}
		// that.productId
		that.$point.buttonClickFn(button_params);
		if(that.page > that.pageNums){
			console.log('没有更多了')
		}else{
			that.getProductDynamicList()
			that.isActive = true;
		}
    }
  }
}
</script>

<style scoped lang="scss">
.product-news {
  width: 4.5rem;
  background-color: #ffffff;
  border-radius: 0 .2rem .2rem 0;
  padding: .4rem;
  min-height: 6.5rem;
  position: relative;
  .title {
    font-size: .2rem;
    color: #503291;
    margin-bottom: .36rem;
    line-height: 1;
    &.icon {
      padding-left: .3rem;
      background: url("~./images/news.png") left center / .2rem .19rem no-repeat;
    }
  }
  .list {
    height: 4.6rem;
    overflow: hidden;
    &.empty-box{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.active{
      overflow-y: scroll;
      overflow-x: hidden;
    }
    li {
      position: relative;
      margin-bottom: .4rem;
      cursor: pointer;
    }
    .img {
      display: block;
      width: 100%;
      height: 2.07rem;
      border-radius: .08rem .4rem .08rem .08rem;
    }
    .t {
      position: absolute;
      left: .15rem;
      right: .15rem;
      bottom: .45rem;
      color: #fff;
      font-size: .18rem;
      line-height: .24rem;
      max-width: 100%;
      z-index: 2;
      text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: bold;
      &.time{

          bottom:.15rem;
          background: url("~./images/time.png") no-repeat left center/0.16rem 0.16rem;
          padding-left:0.2rem;
      }
    }
    .item-content{
      // position: relative;
      // z-index: 2;
    }
    .item-content.itemMinHeight{
      min-height: 2.07rem;
    }

    .shadow{
      width: 100%;
      height: 1.3rem;
      background: linear-gradient(0deg, #000000 0%, rgba(3, 0, 0, 0) 100%);
      opacity: 0.4;
      border-radius: 0 0 .08rem .08rem;
      position: absolute;
      bottom: 0;
      left: 0;
       z-index: 1;
    }

  }
  .more {
    position: absolute;
    left: 50%;
    bottom: -.05rem;
    margin-left: -1.2rem;
    width: 2.4rem;
    height: .69rem;
    background: url("~./images/more-bg.png") center center / 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .more-t {
      display: block;
      height: .28rem;
      padding-left: .35rem;
      color: #fff;
      font-size: .14rem;
      line-height: .28rem;
      background: url("~./images/more.png") left center /.26rem .24rem no-repeat;
    }
  }
}
</style>
